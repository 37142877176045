<template>
  <div
    id="page-container"
    class="home-page-container">
    <el-container class="home-container">
      <el-container class="in-container page-content-margin-sm-and-up">
        <section class="layout-header">
          <div
            class="header-placeholder hiden-visibility"
            :style="{'height':`${height}px`}"></div>
          <el-header
            id="header-height"
            :height="'auto'"
            class="layout-header-fixed">
            <v-header
              @click="handleClick"></v-header>
          </el-header>
        </section>
        <el-main
          class="layout-container">
          <div
            class="layout-main flex-1 flex-col">
            <router-view></router-view>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import VHeader from '../components/v-header'
import MixinAllOptions from '@/mixins/all-options'
export default {
  name: 'HomeContainer',
  components: {
    VHeader
  },
  mixins: [MixinAllOptions],
  props:{
    step: {
      type: Number,
      default: 1,
    },
    btnText: {
      type: String,
      default: 'Cancel',
    },
    title:{
      type: Object,
      default: ()=>({}),
    }
  },
  data() {
    return {
      height:0
    }
  },
  computed: {
  },
  mounted() {

    // let height = document.body.clientHeight
    const element = document.getElementById('header-height')
    this.height = element.clientHeight +20
    // document.getElementById('scroll-content').style.height=height
    // console.log('document.getElementById(\'scroll-content\').style.height',document.getElementById('scroll-content').style.height)
  },
  methods: {
    handleClick(){
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-header{
  width: 100vw;
}
.layout-header-fixed{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 2001;
  /deep/ .share-header{
    position: relative;
  }
  &.isShadow{
    box-shadow: 0 5px 20px rgba(0, 35, 124, 0.08);
  }
}
.flex-1 {
  min-width: 10px;
  min-height: 10px;
  flex: 1;
}
.home-page-container {
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  background:#fff;
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
  .home-container{
    width: 100%;
    height: 100%;
  }
  .in-container {
    //width: 100%;
    //height: 100vh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    //height: calc(100% - 60px);
  }
  .el-header {
    padding: 0;
    z-index: 10;
    //min-height: 160px!important;
    width: 100%;
    //position: fixed;
    background: #fff;
  }
  .el-main{
    padding:0
  }
  .layout-container {
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 32px;
    padding-right: 32px;
    min-height: 100vh;
    padding-bottom: 100px;
    @media screen and (max-width: 420px) {
      padding-left: 20px;
      padding-right: 20px;
      //padding-top: 146px;
    }
  }
  .scroll-content{
    //height: 100vh;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 200px;
    //padding-top: 160px;
    @media screen and (max-width: 420px) {
      padding-left: 20px;
      padding-right: 20px;
      //padding-top: 146px;
    }
    .quoting-page{
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 100px;
      //-webkit-overflow-scrolling: touch;
    }
  }

}
</style>

